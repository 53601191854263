// eslint-disable-next-line import/prefer-default-export
export const columnConfig = [
  {
    key: 'pickup_date',
    label: 'Waktu Pengajuan',
    thClass: 'text-black text-capitalize',
    class: 'p-1',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      fontWeight: 600,
    },
    tdClass: 'text-black',
  },
  {
    key: 'partner_name',
    label: 'Partner',
    thClass: 'text-black text-capitalize',
    class: 'p-1',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      fontWeight: 600,
    },
    tdClass: 'text-black',
  },
  {
    key: 'total_order',
    label: 'Order',
    thClass: 'text-black text-capitalize',
    class: 'p-1',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      fontWeight: 600,
    },
    tdClass: 'text-black',
  },
  {
    key: 'total_product',
    label: 'Produk',
    thClass: 'text-black text-capitalize',
    class: 'p-1',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      fontWeight: 600,
    },
    tdClass: 'text-black',
  },
  {
    key: 'fulfillment_cost',
    label: 'Total Komisi',
    thClass: 'text-black text-capitalize',
    class: 'p-1',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      fontWeight: 600,
    },
    tdClass: 'text-black',
  },
  {
    key: 'is_print',
    label: 'Status Packing',
    thClass: 'text-black text-capitalize',
    class: 'p-1 text-center',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      fontWeight: 600,
    },
    tdClass: 'text-black',
  },
  {
    key: 'detail',
    label: 'Rincian',
    thClass: 'text-black text-capitalize',
    class: 'p-1',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      fontWeight: 600,
    },
    tdClass: 'text-black',
  },
]
