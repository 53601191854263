<template>
  <div>
    <BRow class="mt-1 justify-end mr-0">
      <BDropdown
        variant="outline-dark"
        :text="handleTextDropdown(partnerId)"
        menu-class="max-h-[15rem] overflow-auto"
        class="mr-1"
        size="md"
      >
        <BDropdownItem @click="filterPartnerId(partnerId)">
          Semua Partner
        </BDropdownItem>
        <BDropdownItem
          v-for="data in partner"
          :key="data.id"
          v-model="partnerId"
          :active="activeItem(data.id)"
          @click="filterPartnerId(data)"
        >
          {{ data.name }}
          <span
            v-if="data.unsubscribe === 1"
            class="ml-1 unsubscribe-tag"
          >Unsubscribe</span>
        </BDropdownItem>
      </BDropdown>
      <DateRangePicker
        ref="picker"
        v-model="dateRange"
        :locale-data="locale"
        :ranges="ranges"
        :opens="'left'"
      >
        <template
          v-slot:input="picker"
          style="min-width: 350px"
        >
          <div class="d-flex justify-content-between align-items-center w-100">
            <div class="mr-1">
              <span
                v-if="YEAR_MONTH_DAY(picker.startDate) === YEAR_MONTH_DAY(today)"
                style="color: #828282 !important"
              >
                Hari ini
              </span>
              <span
                v-else-if="YEAR_MONTH_DAY(picker.startDate) === YEAR_MONTH_DAY(last7)"
                style="color: #828282 !important"
              >
                7 Hari Terakhir
              </span>
              <span
                v-else-if="YEAR_MONTH_DAY(picker.startDate) === YEAR_MONTH_DAY(last30)"
                style="color: #828282 !important"
              >
                30 Hari Terakhir
              </span>
              <span
                v-else-if="YEAR_MONTH_DAY(picker.startDate) === YEAR_MONTH_DAY(firstDateOfMonth) && YEAR_MONTH_DAY(picker.endDate) === YEAR_MONTH_DAY(today)"
                style="color: #828282 !important"
              >
                Bulan ini
              </span>
              <span
                v-else
                style="color: #828282 !important"
              > Custom </span>
            </div>
            <img
              src="https://storage.googleapis.com/komerce/assets/icons/calendar.png"
              alt="Komerce"
            >
          </div>
        </template>
      </DateRangePicker>
      <img
        v-b-tooltip.hover.top="'Reset Filter'"
        class="ml-[10px] cursor-pointer"
        src="https://storage.googleapis.com/komerce/assets/icons/refresh-2.svg"
        alt="Komerce"
        @click="handleResetFilter"
      >
    </BRow>
    <BOverlay
      :show="loading"
      spinner-variant="primary"
      variant="light"
      blur="0"
      opacity=".5"
      rounded="sm"
    >
      <BTable
        id="table"
        :items="items"
        :fields="fields"
        class="mt-2"
        empty-text="Tidak ada data yang ditampilkan"
        responsive
        show-empty
      >
        <template #cell(pickup_date)="data">
          <div>{{ DAY_MONTH_YEAR(data.item.submit_date) }}</div>
          <div class="text-[#828282]">
            {{ TIME(data.item.submit_date) }}
          </div>
        </template>
        <template #cell(partner_name)="data">
          <div class="flex items-center">
            <BAvatar :src="data.item.business_logo" />
            <span class="ml-1">{{ data.item.partner_name }}</span>
          </div>
        </template>
        <template #cell(fulfillment_cost)="data">
          {{ IDR(data.item.fulfillment_cost) }}
        </template>
        <template #cell(is_print)="data">
          <div
            class="relative"
            :class="data.value ? 'green-bar-container':'blue-bar-container'"
          >
            {{ data.value ? 'Diprint' : 'Belum Diprint' }}
          </div>
        </template>
        <template #cell(detail)="data">
          <span
            class="cursor-pointer text-[#f95031] font-medium"
            @click="handleClick(data.item.id)"
          >
            Lihat Detail
          </span>
        </template>
      </BTable>
    </BOverlay>
  </div>
</template>
<script>

import provider from '@/provider/provider'
import { newAxiosIns } from '@/libs/axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { WAREHOUSE_SUBSCRIBERS } from '@/provider/url'
import {
  DAY_MONTH_YEAR, LABELDAY, LABELMONTH, TIME, YEAR_MONTH_DAY,
} from '@/libs/filterDate'
import { IDR } from '@/libs/currency'
import DateRangePicker from 'vue2-daterange-picker'
import {
  firstDateOfMonth, komshipDate, last30, last7, today,
} from '@/store/helpers'
import { columnConfig } from './config'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'

export default {
  components: { DateRangePicker },
  data() {
    return {
      loading: false,
      items: [],
      fields: columnConfig.filter(column => column.key !== 'fulfillment_cost'),
      DAY_MONTH_YEAR,
      TIME,
      IDR,
      dateRange: {
        startDate: komshipDate,
        endDate: today,
      },
      locale: {
        format: 'dd/mm/yyyy',
        daysOfWeek: LABELDAY,
        monthNames: LABELMONTH,
      },
      ranges: {
        'Hari ini': [today, today],
        '7 Hari Terakhir': [last7, today],
        '30 Hari terakhir': [last30, today],
        'Bulan ini': [firstDateOfMonth, today],
        'Custom ': [komshipDate, today],
      },
      today,
      last7,
      last30,
      komshipDate,
      firstDateOfMonth,
      YEAR_MONTH_DAY,
      resetFilter: true,
      limit: 25,
      offset: 0,
      lastData: false,
      partnerId: '',
      partnerName: '',
      partner: [],

    }
  },
  watch: {
    dateRange: {
      handler() {
        this.getData()
      },
    },
    partnerId: {
      handler() {
        this.getData()
      },
    },
  },
  mounted() {
    this.getData()
    this.getPartner()
  },
  created() {
    window.onscroll = () => {
      if ((window.innerHeight + window.scrollY) >= document.getElementById('table').offsetHeight && !this.loading) {
        this.getNextData()
      }
    }
  },
  methods: {
    async getData() {
      this.offset = 0
      this.loading = true
      const startDate = YEAR_MONTH_DAY(this.dateRange.startDate)
      const endDate = YEAR_MONTH_DAY(this.dateRange.endDate)

      const params = {
        start_date: startDate,
        end_date: endDate,
        limit: this.limit,
        offset: this.offset,
        partner_id: this.partnerId,
      }

      this.$emit('params', {
        start_date: startDate,
        end_date: endDate,
        partner_id: this.partnerId,
      })

      await newAxiosIns.get('kompack/api/v1/mitra/outbounds', { params })
        .then(res => {
          const { data } = res.data
          this.items = data
          this.loading = false
          this.offset = data.length
          if (data.length < this.limit) {
            this.lastData = true
          } else {
            this.lastData = false
          }
        })
        .catch(err => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failure',
              icon: 'AlertCircleIcon',
              text: err.response.data.message,
              variant: 'danger',
            },
          }, { timeout: 2000 })
          this.loading = false
        })
    },
    async getPartner() {
      await newAxiosIns.get(`${WAREHOUSE_SUBSCRIBERS}`)
        .then(res => {
          const { data } = res.data
          this.partner = data
        })
        .catch(err => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failure',
              icon: 'AlertCircleIcon',
              text: err.response.data.message,
              variant: 'danger',
            },
          }, { timeout: 2000 })
          this.loading = false
        })
    },
    async getNextData() {
      const startDate = YEAR_MONTH_DAY(this.dateRange.startDate)
      const endDate = YEAR_MONTH_DAY(this.dateRange.endDate)
      const params = {
        start_date: startDate,
        end_date: endDate,
        limit: this.limit,
        offset: this.offset,
        partner_id: this.partnerId,
      }
      if (!this.lastData) {
        this.loading = true
        await newAxiosIns.get('kompack/api/v1/mitra/outbounds', { params })
          .then(res => {
            const { data } = res.data
            this.items.push(...data)
            this.offset += data.length
            this.loading = false
            if (data.length < this.limit) {
              this.lastData = true
            }
          })
          .catch(err => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Failure',
                icon: 'AlertCircleIcon',
                text: err.response.data.message,
                variant: 'danger',
              },
            }, { timeout: 2000 })
            this.loading = false
          })
      }
    },
    handleClick(id) {
      this.$router.push({
        path: `/packing-non-komship/detail/${id}`,
      })
    },
    async handleResetFilter() {
      this.partnerId = ''
      this.partnerName = ''
      this.dateRange = {
        startDate: komshipDate,
        endDate: today,
      }

      this.getData()
    },
    handleTextDropdown(data) {
      if (data) return this.partnerName
      return 'Semua Partner'
    },
    filterPartnerId(data) {
      const { id, name } = data
      this.partnerId = id
      this.partnerName = name
    },
    activeItem(id) {
      return this.partnerId === id
    },
  },
}
</script>
<style scoped>
.green-bar-container {
  border: 1px solid #34A770;
  border-radius: 10px;
  height: 20px;
  color: #007F00;
  text-align: center;
}
.blue-bar-container {
  border: 1px solid #08A0F7;
  border-radius: 10px;
  height: 20px;
  color: #08A0F7;
  text-align: center;
}

.unsubscribe-tag {
  border-radius: 4px;
  border: 1px solid currentColor;
  background: transparent;

  padding: 2px 6px;
  color: currentColor
}
</style>
