<template>
  <BCard>
    <div class="text-black mb-2 text-2xl font-semibold">
      Data Packing
    </div>
    <div class="mb-2">
      <!-- set query params tab when clicked -->
      <b-tabs
        pills
        active-nav-item-class="!rounded-full !text-white !font-medium"
        nav-class="gap-4 !font-medium md:absolute"
      >
        <b-tab
          :active="$route.query.tab === 'komship'"
          title-item-class="!rounded-full !border border-[#f95031]"
          title-link-class="!text-[#f95031]"
          @click="() => $router.push({ query: { tab: 'komship' } })"
        >
          <template #title>
            <div class="flex gap-[10px] items-center"><span class="leading-normal">Komship</span>
              <b-badge
                v-if="totalPackingKomship !== 0"
                :variant="$route.query.tab === 'komship' ? 'light' : 'primary'"
              ><span :class="$route.query.tab === 'komship' ? 'text-primary' : ''">{{ totalPackingKomship }}</span></b-badge>
            </div>
          </template>
          <PackingKomship @params="data => komshipParams = data" />
        </b-tab>
        <b-tab
          :active="$route.query.tab === 'non-komship'"
          title-item-class="!rounded-full !border border-[#f95031]"
          title-link-class="!text-[#f95031]"
          @click="() => $router.push({ query: { tab: 'non-komship' } })"
        >
          <template #title>
            <div class="flex gap-[10px] items-center"><span class="leading-normal">Non Komship</span>
              <b-badge
                v-if="totalPackingNonKomship !== 0"
                :variant="$route.query.tab === 'non-komship' ? 'light' : 'primary'"
              ><span :class="$route.query.tab === 'non-komship' ? 'text-primary' : ''">{{ totalPackingNonKomship }}</span></b-badge>
            </div>
          </template>
          <PackingNonKomship @params="data => nonKomshipParams = data" />
        </b-tab>
      </b-tabs>
    </div>
  </BCard>
</template>

<script>
import { newAxiosIns } from '@/libs/axios'
import PackingKomship from './List.vue'
import PackingNonKomship from './NonKomship.vue'

export default {
  components: {
    PackingKomship, PackingNonKomship,
  },
  data() {
    return {
      totalPackingKomship: 0,
      totalPackingNonKomship: 0,
      komshipParams: {},
      nonKomshipParams: {},
    }
  },
  watch: {
    komshipParams: {
      handler() {
        this.getTotalPackingKomship()
      },
      deep: true,
    },
    nonKomshipParams: {
      handler() {
        this.getTotalPackingNonKomship()
      },
      deep: true,
    },
  },
  beforeCreate() {
    if (!this.$route.query.tab) {
      this.$router.push({ query: { tab: 'komship' } })
    }
  },
  methods: {
    async getTotalPackingNonKomship() {
      const params = { ...this.nonKomshipParams }
      const { data } = await newAxiosIns.get('/kompack/api/v1/mitra/total-outbounds', { params }).then(res => res.data)
      this.totalPackingNonKomship = data.total
    },
    async getTotalPackingKomship() {
      const params = { ...this.komshipParams }
      const { data } = await newAxiosIns.get('/komship/api/v1/pickup/total', { params }).then(res => res.data)
      this.totalPackingKomship = data.total
    },
  },
}
</script>
